.heading {
    text-align: center;
    padding: 15px 0px;
}

.red-text {
    color: #7A0404;
}

.no-padding {
    padding: 0;
}

.text-white {
    color: #ffffff;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.space-around {
    justify-content: space-around;
}

.h-half {
    height: 50%;
}
.toolbar-title {
    background: #ffca22 !important;
}